import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState, useEffect, FormEvent } from "react"
import { Helmet } from "react-helmet"
import Loading from "../../components/Loading"
import { formatAddress, formatPhoneNumber } from "../../utils"
import { lambdaUrl } from "../../config";

const DoubleCheckAddress = ({ formData, setPage }: any) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [phone, setPhone] = useState('');
  const [phone_error, setPhoneError] = useState('');
  const loaderTitle = 'Checking Address';
  const nextStep = async (e: any) => {
    setPage(1)
  }
  const checkPhoneNo = async (phone: any) => {
    if (phone) {
      // var phoneValid = phone.match(/^(\+?1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
      // if (!phoneValid) {
      //   setPhoneError('Please enter a valid phone number.');
      // } else {
      //   setPhoneError('');
      //   phone= ['(', phoneValid[2], ') ', phoneValid[3], '-', phoneValid[4]].join('');
      // }
      let pNumber = phone.replace(/\D/g, "")
      phone = formatPhoneNumber(pNumber);
      let phoneValid = pNumber.match(/^(\+1 ?)?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
      if (!phoneValid) {
        phoneValid = pNumber.match(/^(\+?1 ?)?\(?([0-9]{1})\)?[-. ]?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})/);
      }
      if (!phoneValid) {
        setPhoneError('Please enter a valid phone number.');
      } else {
        setPhoneError('');
      }

    }
    setPhone(phone);
  }
  
  function sendEmail(e: FormEvent) {  
    e.preventDefault()
    if (!phone_error && phone) {
      setError(false)
      setLoading(true)
      //serverApiUrl + "email-salesforce"
      fetch(lambdaUrl+"/email-salesforce", {
        method: "POST",
        body: JSON.stringify({
          address: { Label: formatAddress(formData.shipping_address) },
          phone,
        }),
      })
        .then((res) => res.json())
        .then(() => {
          setLoading(false)
          setFormSent(true)
        })
        .catch(() => {
          setLoading(false)
          setError(true)
        })
    }
  }
  useEffect(() => { window.scrollTo(0, 0) }, [])
  return (
    <>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}

        <title>Plan – Purple Cow Internet 💜🐄</title>
      </Helmet>
      <main className="max-w-3xl mx-auto">
        <div className="body--lato height-100-btn-bottom text-center">
          <div className="contact-page-center p-lr-15">
            <div className="text-center logo-center">
              <Link to="/">
                <StaticImage
                  className="w-24"
                  layout="constrained"
                  src="../content/cow.png"
                  alt="Purple Cow Internet 💜🐄"
                />
              </Link>
            </div>
            {loading && <Loading loaderData={loaderTitle} />}
            <h1 className="mb-10 text-4xl text-center text-gray-600">
              I'm going to double check this address
            </h1>

            {error && (
              <div>
                There was a problem sending a message, you can try texting us
                at:
                <div>1-902-967-4877</div>
              </div>
            )}
            {formSent && !loading && !error && (
              <p className="text-xl font-thin text-center text-gray-500">
                We'll shoot you a text back at the provided number.
              </p>
            )}
            {formSent && !loading && !error && (
              <div className="button-box steps-bottom-row button-seven-step">
                <button className="btn btn-primary-outline" type="button" onClick={nextStep}> Back </button>
              </div>
            )}
            {!loading && !formSent && (
              <>
                <p className="text-center mb-4 text-xl font-thin text-center text-gray-500">
                  What's the best number to text you?
                </p>
                <form onSubmit={sendEmail}>
                  <input
                    className="w-full"
                    maxLength={40}
                    name="mobile"
                    size={20}
                    type="tel"
                    placeholder="Mobile Phone*"
                    required
                    onChange={(e) => checkPhoneNo(e.target.value)}
                    value={phone}
                  />
                  <span className="error-field">{phone_error}</span>
                  <div className="button-box steps-bottom-row button-seven-step">
                    <button className="btn btn-primary-outline" type="button" onClick={nextStep}> Back </button>
                    <button className="btn bg-primary-700 text-white" type="submit"> Next </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </main>
    </>
  )
}

export default DoubleCheckAddress
