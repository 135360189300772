import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import React, { useState, useEffect, FormEvent } from "react"
//const Checkout = ({ nextStep, prevStep, contactPageData, setFormStep, setSuccessMessage }: any) => {
const PaymentSuccessComponent = ({ setPage }: any) => {
    const nextStep = async (e: any) => {
        setPage(1)
    }

    return (
        <>
                <Helmet>
                    {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                    <title>Plan – Purple Cow Internet 💜🐄</title>
                </Helmet>
                <main className="max-w-3xl mx-auto">
                    <div className="body--lato height-100-btn-bottom text-center">
                        <div className="contact-page-center width-unset">
                            <div className="text-center logo-center">
                                <Link to="/">
                                    <StaticImage
                                        className="w-24"
                                        layout="constrained"
                                        src="../../content/cow.png"
                                        alt="Purple Cow Internet 💜🐄"
                                    />
                                </Link>
                                <p>&nbsp;</p>
                                <h1 className="mb-10 text-4xl text-center text-gray-600">Golly, that was easy!</h1>
                                <div className="px-4 prose prose-lg text-align-left-p">
                                    <h3 className="mb-2 text-xl text-center">Here are some next steps:</h3>
                                    <p>Super happy you jumped the fence. You should be receiving a text from us any minute confirming your phone number and your addresses. Please respond confirming everything so we can begin processing your account. If for some reason you do not receive a text, feel free to let us know by replying to the email we also sent you.</p>
                                    <p>The first thing we are going to do is get a modem activated for you. We share a lot of lines with Eastlink so we will let them know we will be sending traffic to your home. Please note that it generally takes 4-7 business days to get connected. Sometimes a bit longer if a technician is required.</p>
                                    <p>We will send you tracking information when your modem mails. Once it arrives sometimes we are still waiting for everything to be activated however we keep you in the loop every step of the way.</p>
                                    <p>Once the modem is activated all you need to do is just plug it in to the coax port in your home. For some reason if it does not work after plugging it in we will send a tech to assist. At no cost of course.</p>
                                    <p>That’s it! Feel free to respond to any of the texts as it is our main form of communication and there is a team of us making sure you have an awesome experience!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
        </>
    )
}
export default PaymentSuccessComponent
