import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { formatMoney, priceFormat } from "../../utils";
import { getAddonPlanById, getInternetPlanById, getMainChannels, getMonthlyAndSingleTimeAddonPlan, getAllTvOptionalPlan, getAdditionalSingleChannelsName } from "../../customfunction";
import MenuComponent from "./MenuComponent";
import TvPlansOptional from "./TvPlansOptional";
import LoaderComponent from "./LoaderComponent"
const TvPlansComponent = ({ formData, setformData, setPage, plans, addons, page }: any) => {
    if (!plans || !addons || !formData) {
        return <LoaderComponent values="Loading....."/>;
    }
    const [isContentVisible, setContentVisible] = useState(true);
    const [contentHeight, setContentHeight] = useState(0);
    const [OptionisContentVisible, setOptionisContentVisible] = useState(true);
    useEffect(() => {
        setOptionisContentVisible(isContentVisible);
    }, [isContentVisible])
    const TvPlansList = plans ? plans.tv : []; //getMonthlyAndSingleTimeAddonPlan(addons, 'tv', formData);
    let seletedPlan = (formData?.plan_id != null && plans) ? plans.internet.filter(obj => obj.id === formData?.plan_id)[0] : null; // getInternetPlanById(plans, formData?.plan_id) : null;
    let seletedTvPlan = (formData?.tv_plan != null) ? TvPlansList.filter(obj => obj.id === formData?.tv_plan)[0] : null; // getAddonPlanById(addons, formData?.tv_plan) : null;
    let total = seletedPlan ? seletedPlan?.billing[0].monthly.price : 0;
    if (seletedTvPlan != null) {
        total += seletedTvPlan?.billing_period[0].monthly.price;
    }
    const [subChannelsIcon, setsubChannelsIcon] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const PlanChangeRadio = async (e: any) => {
        if (e.target.checked) {
            formData.tv_plan = Number(e.target.value);
            setContentVisible(false);
            seletedTvPlan = (formData?.tv_plan != null) ? TvPlansList.filter(obj => obj.id === formData?.tv_plan)[0] : null;
            setformData({
                ...formData,
                tv_plan: Number(e.target.value),
                OptionalPagePlan: [...seletedTvPlan.optional_extra_packages, ...seletedTvPlan.optional_iptv_products] ,
                OptionalPagePlansIcons: seletedTvPlan?.optional_single_channels,
                OptionalPagePlansIconsName: []
            });
        } else {
            let updatedFormData = { ...formData, tv_optional_plans: [], tv_optional_single_plans: [], tv_optional_single_plans_name: [] };
            let z = 0;
            if (formData?.addons.length > 0) {
                let metaD: any = '';
                let allPlanData = formData?.addons;
                let addonPlanObj: any = formData?.selectedAddonPlanData;
                formData?.addons.forEach((item: any) => {
                    metaD = getAddonPlanById(addons, item);
                    if (metaD) {
                        let typechanel = metaD?.meta_data?.is_tv_addon;
                        if (typeof typechanel !== "undefined" && typechanel === true) {
                            allPlanData.splice(allPlanData.indexOf(item), 1);
                            z = 0;
                            addonPlanObj.forEach((items: any) => {
                                if (items.id === item) {
                                    addonPlanObj.splice(z, 1);
                                }
                                z++;
                            });
                        }
                    }
                });
                updatedFormData.addons = allPlanData;
                updatedFormData.selectedAddonPlanData = addonPlanObj;
            }
            updatedFormData.tv_plan = null;
            setformData(updatedFormData);
        }
    };

    const nextStep = async (e: any) => {
        setPage(7);
    }
    const prevStep = async (e: any) => {
        setPage(4);
    }

    const getSubChannels = (id: any, type: any = "") => {
        setShowModal(true);
        setsubChannelsIcon(TvPlansList.filter(obj => obj.id === id)[0]?.included_channels);
        if (type == 'get') {

        }
    }

    useEffect(() => {
        if (formData?.hideTvPlan) {
            setContentVisible(true);
        }
    }, [formData?.hideTvPlan])

    if (typeof window !== 'undefined' && localStorage.getItem('pageChange')) {
        if (formData?.tv_plan != null) {
            setContentVisible(false);
        }
        localStorage.removeItem('pageChange')
    }

    useEffect(() => {
        if (formData?.tv_plan != null) {
            setContentVisible(false);
        }
    }, [])


    useEffect(() => {
        const tvMainElement = document.getElementById('tv-main-height');

        if (tvMainElement) {
            setContentHeight(tvMainElement.clientHeight);
        }
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [isContentVisible])

    return (
      <>
          <Helmet>
              <title>Plan – Purple Cow Internet 💜🐄</title>
          </Helmet>
          <main className="max-w-3xl mx-auto">
              <div className="body--lato height-100-btn-bottom view-incluid-outside">
                  <div>
                      <div className="text-center logo-center">
                          <Link to="/">
                              <StaticImage
                                className="w-24"
                                layout="constrained"
                                src="../content/cow.png"
                                alt="Purple Cow Internet 💜🐄"
                              />
                          </Link>
                      </div>
                      <MenuComponent page={page} setPage={setPage}></MenuComponent>
                      <div className={`slide-up ${isContentVisible ? 'mx-auto visible' : ''}`} id="tv-main-height">
                          <h3 className="mt-12 heading-h2 mb-4">Pick an optional TV plan.</h3>
                          <div className="selectPlan addon-plan-1">
                              <p className="p4 mb-8">
                                  Purple Cow does not provide a TV box because the Purple Cow TV app can be viewed on any Android, Apple or
                                  Amazon device. Soon, you'll also be able to stream through your web browser.
                              </p>
                              {TvPlansList.map((item: any, _index: any) => {
                                  return (<><div className="selectPlan__optional" key={'tv-pln-key' + _index}><label className="selectPlan__box">
                                        <input
                                          type="checkbox"
                                          name="selectPlan__"
                                          value={item?.id}
                                          checked={formData?.tv_plan == item?.id}
                                          onChange={PlanChangeRadio}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{item?.billing_period[0].monthly.invoice_name}</h3>
                                            <h2 className="h2">
                                                ${priceFormat(item?.billing_period[0].monthly.price)}
                                            </h2>
                                            <p className="plan-type">
                                                / month
                                            </p>
                                            <p className="plan-type">{item.included_channels.length} Channels</p>
                                            <div className="chennels-box">
                                                {item.featured_channels.map(
                                                  (itemMain: any, _indexmain: any) => {
                                                      return (
                                                        <>
                                                            <div className="chennels-box__icon" key={_indexmain + 'b'}>
                                                                <img
                                                                  src={itemMain.image_url}
                                                                  alt=""
                                                                  className="chennels-box__img"
                                                                />
                                                            </div>
                                                        </>
                                                      )
                                                  }
                                                )}
                                            </div>
                                            <p className="p4">
                                                {
                                                    item?.description ?
                                                      item?.description.split("\n").map(function (items_description: any, idx: any) {
                                                          return (
                                                            <span key={'newkey' + idx}>
                                                                    {items_description}
                                                                <br />
                                                                </span>
                                                          )
                                                      })
                                                      : ''
                                                }
                                            </p>
                                        </div>
                                    </label>
                                        <span
                                          className="a-link-bold"
                                          onClick={() => getSubChannels(item?.id)}
                                        >
                                            View Included Channels
                                        </span>
                                    </div>
                                    </>
                                  )
                              })}
                          </div>
                          <div className="steps-bottom-row">
                              <div className="flex">
                                  <div className="steps-bottom-row__month-payment-box">
                                      <span className="p4">Monthly Payment</span>
                                      <h3 className="h3">
                                          {formatMoney(total)}
                                      </h3>
                                  </div>
                              </div>
                              <div className="button-box">
                                  <button className="btn btn-primary-outline" onClick={prevStep}>
                                      {" "}
                                      Back{" "}
                                  </button>
                                  <button
                                    className="btn bg-primary-700 text-white" onClick={nextStep}
                                  >
                                      {" "}
                                      Next{" "}
                                  </button>
                              </div>
                          </div>
                      </div>
                      <div className={`slide-up1 ${!OptionisContentVisible ? 'mx-auto visible' : ''}`} id="tv-main-height1">
                          <TvPlansOptional formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} />
                      </div>
                  </div>
              </div>
          </main>
          {showModal ? (
            <>
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                            <button
                              className="ml-auto mt-0 bg-transparent border-0 text-black leading-none outline-none focus:outline-none"
                              onClick={() => setShowModal(false)}
                            >
                                    <span className="bg-transparent opacity-25 text-black h-6 w-6 text-4xl block outline-none focus:outline-none mr-2">
                                        ×
                                    </span>
                            </button>
                            <div className="text-center">
                                <h4 className="text-1xl text-gray-600">Included Channels</h4>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="#47525E"
                                  className="w-6 h-6 m-auto cursor-pointer"
                                >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                </svg>
                            </div>
                            <div className="relative p-6 chennel-modal-parent">
                                <div className="chennels-box">
                                    {subChannelsIcon.map((itemMain: any, _indexmains: any) => {
                                        return (
                                          <>
                                              <div className="chennels-box__icon" key={_indexmains + 's'}>
                                                  <img src={itemMain.image_url}></img>
                                              </div>
                                          </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
      </>
    )
}

export default TvPlansComponent;
