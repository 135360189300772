import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import React, { useState, useEffect, FormEvent } from "react"
//const Checkout = ({ nextStep, prevStep, contactPageData, setFormStep, setSuccessMessage }: any) => {
const InvalidForServiceAddress = ({ setPage }: any) => {
    const nextStep = async (e: any) => {
        setPage(1)
    }
 
    return (
        <>
            <Helmet>
                {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                <title>Plan – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <main className="max-w-3xl mx-auto">
                <div className="body--lato height-100-btn-bottom text-center">
                    <div className="contact-page-center">
                        <div className="text-center logo-center">
                            <Link to="/">
                                <StaticImage
                                    className="w-24"
                                    layout="constrained"
                                    src="../content/cow.png"
                                    alt="Purple Cow Internet 💜🐄"
                                />
                            </Link>
                            <h1 className="mb-10 text-4xl text-center text-gray-600">Dang! Looks like we are not able to get our internet into your home yet.</h1>
                            <div className="text-xl font-thin text-center text-gray-500"><p>Sorry about that and thank you for thinking of Purple Cow.</p></div>
                            <div className="button-box steps-bottom-row button-seven-step">
                                <button className="btn btn-primary-outline" type="button" onClick={nextStep}> Back </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default InvalidForServiceAddress
