import React, { useState, useEffect, useCallback } from "react"
import { lambdaUrl } from "../config"
import { useReferralState, useUpdateReferralState, setReferralState } from "../ReferralWrapper"
import AddonPlansComponent from "./new-pages/AddonPlansComponent"
import AddressComponent from "./new-pages/AddressComponent"
import CheckoutComponent from "./new-pages/CheckoutComponent"
import ContactComponent from "./new-pages/ContactFormComponent"
import { defaultFormData, resetMainUrl } from "../customfunction"
import DoubleCheckAddress from "./new-pages/DoubleCheckAddressComponent"
import InternetPlansComponent from "./new-pages/InternetPlansComponent"
import InvalidForServiceAddress from "./new-pages/InvalidAddress"
import LoaderComponent from "./new-pages/LoaderComponent"
import PaymentSuccessComponent from "./new-pages/PaymentSuccess"
import TvPlansComponent from "./new-pages/TvPlansComponent"
import TvPlansOptional from "./new-pages/TvPlansOptional"
import { getSelectedPlanData } from "../customfunction";
import { formatAddressWithApartmentNumber, decrypt } from "../utils"
import { crudLocalStoarge, localStorageKeys } from "../localStorage"
import customers from "raw-loader!../content/new-pictou-customers.txt";
import fibercustomers from "raw-loader!../content/new-purple-cow-fiber.txt";
import { parseISO } from 'date-fns';
import Cookies from "universal-cookie"

const PickPlans = () => {
  const cookies = new Cookies()
  const referralUrl = useReferralState();
  const useUpdateReferral = useUpdateReferralState();
  const [page, setPage] = useState(0);
  const [plans, setPlans]: any = useState({});
  const [addons, setAddons]: any = useState({});
  const [formData, setformData]: any = useState(defaultFormData());
  const [isCartLoad, setIsCartLoad]: any = useState(false);
  /**
   * check address for pictout and fiber customer
   */
  const pictoutCustomer: any = new Promise((resolve, reject) => {
    try {
      const parsedCustomers = JSON.parse(customers);
      resolve(parsedCustomers);
    } catch (error) {
      reject(error);
    }
  });

  const fiberCustomer: any = new Promise((resolve, reject) => {
    try {
      const fibercustomerss = JSON.parse(fibercustomers);
      resolve(fibercustomerss);
    } catch (error) {
      reject(error);
    }
  });


  async function checkpictoutCustomerAddress(caddress: any) {
    let customer_address_list: any = await pictoutCustomer;
    if (customer_address_list) {
      return customer_address_list.some((exclusion: any) =>
        Object.keys(exclusion).every(
          (key: string) =>
            exclusion[key as keyof Address] === caddress[key as keyof Address]
        )
      )
    }
    return false;
  }

  async function checkFibercustomerAddress(caddress: any) {
    let customer_address_list: any = await fiberCustomer;
    if (customer_address_list) {
      return customer_address_list.some((exclusion: any) =>
        Object.keys(exclusion).every(
          (key: string) =>
            exclusion[key as keyof Address] === caddress[key as keyof Address]
        )
      )
    }
    return false;
  }

  const areAddressesSame = (address1: any, address2: any) => {
    return (
      address1?.Line1 === address2?.Line1 &&
      address1?.SubBuilding === address2?.SubBuilding &&
      address1?.City === address2?.City &&
      address1?.ProvinceCode === address2?.ProvinceCode &&
      address1?.PostalCode === address2?.PostalCode
    );
  }

  const calculateInternetTvTotal = (data: any) => {
    const seletedPlan = plans.internet.filter(plan => plan.id === data?.plan_id)[0];
    const seletedTvPlan = data?.tv_plan ? plans.tv.filter(plan => plan.id === data?.tv_plan)[0] : null;
    let total = 0;
    if (seletedPlan) {
      total += seletedPlan.billing[0].monthly.price;
    }
    if (seletedTvPlan) {
      total += seletedTvPlan.billing_period[0].monthly.price;
      total += [...seletedTvPlan.optional_extra_packages, ...seletedTvPlan.optional_iptv_products].filter(a => data.tv_optional_plans.includes(a.id)).map(a => a.billing_period[0].monthly.price).reduce((a, b) => a + b, 0);
      const numberOfSingleChannels = data.tv_optional_single_plans.length;
      const numberOfBundles = Math.floor(numberOfSingleChannels / 5);
      const remainingItems = numberOfSingleChannels % 5;
      total += (numberOfBundles * addons.tv.filter(obj => obj.api_name === "X_PICK_5")[0].billing_period[0].monthly.price) + (remainingItems * addons.tv.filter(obj => obj.api_name === "X_SNGL_CHANNEL")[0].billing_period[0].monthly.price);
    }
    return total;
  }

  /**
   *
   * check cartUrl if it is valid
   */
  const checkCartUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    if (id) {
      try {
        const rep = urlParams.get('rep');
        const response = await fetch(`${lambdaUrl}query-cart?Cart_ID__c=${id}&Rep=${rep}`);
        if (!response?.ok || response?.status != 200) {
          resetMainUrl();
          crudLocalStoarge('delete', localStorageKeys.CART_ID)
          setPage(1);
          return '';
        }
        let cartDetails = JSON.parse(decrypt(await response.text()));
        if (cartDetails?.Id && !cartDetails?.Checkout_Complete__c) {
          let expires = new Date()
          expires.setTime(expires.getTime() + 2 * 60 * 60 * 1000)
          cartDetails.Coupon_List__c = cartDetails?.Coupon_List__c ? cartDetails?.Coupon_List__c : "";
          let referrals = cartDetails?.Coupon_List__c.split(",").map((referral: string) => referral.trim());
          cookies.set("referral", referrals, {expires});
          setReferralState(referrals);
          const baseInternetPlan = plans.internet.filter(plan => plan.billing[0].monthly.api_name === cartDetails?.Plan__c);
          if (baseInternetPlan.length < 1) {
            resetMainUrl();
            crudLocalStoarge('delete', localStorageKeys.CART_ID)
            setPage(1);
            return '';
          }
          const baseInternetPlanId = baseInternetPlan[0].id;
          let isHomePlan: boolean = false
          if (cartDetails?.Plan_Optional_Addons__c) {
            let addonPlans: any = cartDetails?.Plan_Optional_Addons__c.split(",");
            if (addonPlans.indexOf('monthly_voip_alianza_res_home_phone') !== -1) {
              isHomePlan = true
            }
          }
          const baseTVPlan = cartDetails?.Plan_TV_Base_Package__c ? plans.tv.filter(plan => plan.billing_period[0].monthly.api_name === cartDetails?.Plan_TV_Base_Package__c)[0] : null;
            const is_mailing_address = areAddressesSame({
            Line1: cartDetails?.Service_Street__c,
            SubBuilding: cartDetails?.Service_Suite_Unit__c,
            City: cartDetails?.Service_City__c,
            ProvinceCode: cartDetails?.Service_State_Province__c ? cartDetails?.Service_State_Province__c : "NS",
            BuildingNumber: cartDetails?.Service_Street_Number__c,
            PostalCode: cartDetails?.Service_Zip_Postal_Code__c,
          }, {
            Line1: cartDetails?.Mailing_Street__c,
            SubBuilding: cartDetails?.Mailing_Suite_Unit__c,
            City: cartDetails?.Mailing_City__c,
            ProvinceCode: cartDetails?.Mailing_State_Province__c ? cartDetails?.Mailing_State_Province__c : "NS",
            BuildingNumber: cartDetails?.Mailing_Street_Number__c,
            PostalCode: cartDetails?.Mailing_Zip_Postal_Code__c,
          });
          const ApiformData = {
            ...formData,
            isUpdateCart: 0,
            customer: {
              first_name: cartDetails?.FirstName,
              last_name: cartDetails?.LastName,
              port_number: cartDetails?.Porting_Phone_Number__c ? cartDetails?.Porting_Phone_Number__c : "",
              phone: cartDetails?.Primary_Phone__c,
              email: cartDetails?.Email,
              cf_suggested_turn_on_date: parseISO(cartDetails?.Requested_Install_Date__c),
              cf_company_name: cartDetails?.Business_Name__c,
            },
            shipping_address: {
              Line1: cartDetails?.Service_Street__c,
              SubBuilding: cartDetails?.Service_Suite_Unit__c,
              City: cartDetails?.Service_City__c,
              ProvinceCode: cartDetails?.Service_State_Province__c ? cartDetails?.Service_State_Province__c : "NS",
              BuildingNumber: cartDetails?.Service_Street_Number__c,
              PostalCode: cartDetails?.Service_Zip_Postal_Code__c,
            },
            mailing_address: {
              Line1: cartDetails?.Mailing_Street__c,
              SubBuilding: cartDetails?.Mailing_Suite_Unit__c,
              City: cartDetails?.Mailing_City__c,
              ProvinceCode: cartDetails?.Mailing_State_Province__c ? cartDetails?.Mailing_State_Province__c : "NS",
              BuildingNumber: cartDetails?.Mailing_Street_Number__c,
              PostalCode: cartDetails?.Mailing_Zip_Postal_Code__c,
            },
            is_business: !!cartDetails?.Business_Name__c,
            is_new_phone: isHomePlan ? cartDetails?.Porting_Phone_Number__c ? false : true : false,
            is_existing_phone: isHomePlan ? cartDetails?.Porting_Phone_Number__c ? true : false : false,
            is_mailing: is_mailing_address,
            plan_id: baseInternetPlanId,
            tv_plan: baseTVPlan ? baseTVPlan.id : null,
            tv_optional_plans: cartDetails?.Plan_TV_Extra_Packages__c ? baseTVPlan.optional_extra_packages.filter(addon => cartDetails?.Plan_TV_Extra_Packages__c.split(",").includes(addon.billing_period[0].monthly.api_name)).map(a => a.id) : [],
            addons: cartDetails?.Plan_Optional_Addons__c ? cartDetails?.Plan_Optional_Addons__c.split(",") : [],
            tv_optional_single_plans_name: cartDetails?.Plan_TV_Single_Channels__c ? cartDetails?.Plan_TV_Single_Channels__c.split(",") : [],
            tv_optional_single_plans: cartDetails?.Plan_TV_Single_Channels__c ? baseTVPlan.optional_single_channels.filter(ch => cartDetails?.Plan_TV_Single_Channels__c.split(",").includes(ch.api_name)).map(ch => ch.id) : [],
            OptionalPagePlan: baseTVPlan ? [...baseTVPlan.optional_extra_packages, ...baseTVPlan.optional_iptv_products] : [],
            OptionalPagePlansIcons: baseTVPlan ? baseTVPlan.optional_single_channels : [],
          }
          if (baseTVPlan) {
            const iptvAddons = ApiformData?.addons.filter(a => baseTVPlan.optional_iptv_products.map(p => p.billing_period[0].monthly.api_name).includes(a)).map(a => baseTVPlan.optional_iptv_products.filter(p => p.billing_period[0].monthly.api_name === a)[0].id);
            ApiformData!.tv_optional_plans = [...ApiformData?.tv_optional_plans, ...iptvAddons];
            ApiformData!.addons = ApiformData?.addons.filter(a => !baseTVPlan.optional_iptv_products.map(p => p.billing_period[0].monthly.api_name).includes(a));
          }
          ApiformData.internet_tv_total = calculateInternetTvTotal(ApiformData);
          let totals = 0;
          [...plans.homePhone, ...addons.misc].filter(item => ApiformData.addons.includes(item?.billing_period[0].monthly ? item?.billing_period[0].monthly.api_name : item?.billing_period[0].non_recurring.api_name)).map((item: any) => {
            totals += item?.billing_period[0].monthly ? item?.billing_period[0].monthly.price : 0;
          })
          ApiformData.total = ApiformData.internet_tv_total + totals;
          if (await checkpictoutCustomerAddress(ApiformData?.shipping_address)) {
            ApiformData.Service_Area = "Pictou_Customers";
          } else if (await checkFibercustomerAddress(formData?.shipping_address)) {
            ApiformData.Service_Area = "Purple_Cow_Fiber_Customer";
          } else {
            setformData((formData: any) => ({ ...formData, Service_Area: 'Eastlink_Customer' }))
            ApiformData.Service_Area = "Eastlink_Customer";
          }
          setIsCartLoad(true);
          setformData(ApiformData);
          crudLocalStoarge('add', localStorageKeys.CART_ID, id, 'single');
          useUpdateReferral(referrals);
          setPage(9);
          return true;
        }
        resetMainUrl();
        crudLocalStoarge('delete', localStorageKeys.CART_ID)
        setPage(1);
      } catch (e: any) {
        setPage(1);
      }
    } else {
      setPage(1);
    }
  }

  const getAddonsData = async () => {
    try {
      const response = await fetch(lambdaUrl + "list-addons");
      setAddons(await response.json())
    } catch (error) {

    }
  }

  const getPlansData = async () => {
    try {
      const response = await fetch(lambdaUrl + "list-plans");
      setPlans(await response.json())
    } catch (error) {

    }
  }



  let onFormStateChanged = useCallback((newFormState: any) => {
    setformData((formData) => ({ ...formData, ...newFormState }))
  }, []);

  let addressChange: any = useCallback(
    (shipping_address: any) => onFormStateChanged({ shipping_address }),
    [onFormStateChanged]
  )

  let mailAddressChange = useCallback(
    (mailing_address: any) => onFormStateChanged({ mailing_address }),
    [onFormStateChanged]
  )

  const MonthEstimatePrice = async () => {
    try {
      let planData: any = getSelectedPlanData(formData, plans, addons, 'M');
      setformData((formData) => ({ ...formData, monthEstimate: null }))
      const addonsSelectedData = [
        ...planData?.all_addons,
        ...planData?.additional_plans,
        ...planData?.optional_plans
      ];
      let shipping_address = formData?.shipping_address;
      //throttle((plan_id, addons, shipping_address, referral) => {
      fetch(lambdaUrl + "estimate-new", {
        method: "POST",
        body: JSON.stringify({
          plan_id: planData?.internet_plan_id,
          addons: addonsSelectedData,
          referral: cookies.get('referral').join(","),
          billing_address: formatAddressWithApartmentNumber({
            ...shipping_address,
            country: "CA",
          }),
        }),
      })
        .then((res) => res.json())
        .then((estimate) => {
          if (estimate.status == 200) {
            setformData((formData) => ({ ...formData, monthEstimate: estimate.data.estimate }))
          }
        })
        .catch((e) => {

        })
    } catch (e) { }
  }


  // todo: uncomment
  useEffect(() => {
    if (formData?.plan_id != null) {
      MonthEstimatePrice();
    }
  }, [formData?.plan_id, formData?.tv_plan, formData?.selectedAddonPlanData, formData?.tv_optional_plans, formData?.tv_optional_single_plans, formData?.addons.length]);

  useEffect(() => {
    if (plans?.internet) {
      checkCartUrl();
    }
  }, [plans?.internet])

  useEffect(() => {
    getPlansData();
    getAddonsData();
  }, [])



  useEffect(() => {
    setTimeout(() => {
      if (page != 6) { window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }); }
    }, 300);
  }, [page])



  useEffect(() => {
    if (formData?.customer?.first_name) {
      if (!isCartLoad) {
        setformData({ ...formData, isUpdateCart: 1 });
      } else {
        setIsCartLoad(false);
      }
    }
  }, [
    formData?.customer?.first_name,
    formData?.customer?.last_name,
    formData?.customer?.port_number,
    formData?.customer?.phone,
    formData?.customer?.email,
    formData?.customer?.cf_suggested_turn_on_date,
    formData?.customer?.cf_company_name,
    formData?.shipping_address?.Line1,
    formData?.shipping_address?.SubBuilding,
    formData?.shipping_address?.City,
    formData?.shipping_address?.ProvinceCode,
    formData?.shipping_address?.BuildingNumber,
    formData?.shipping_address?.PostalCode,
    formData?.mailing_address?.Line1,
    formData?.mailing_address?.SubBuilding,
    formData?.mailing_address?.City,
    formData?.mailing_address?.ProvinceCode,
    formData?.mailing_address?.BuildingNumber,
    formData?.mailing_address?.PostalCode,
    formData?.is_business,
    formData?.is_new_phone,
    formData?.is_existing_phone,
    formData?.is_mailing,
    formData?.plan_id,
    formData?.tv_plan,
    formData?.tv_optional_plans,
    formData?.addons,
    formData?.tv_optional_single_plans_name,
    formData?.tv_optional_single_plans,
  ]);


  /*
  *All page view
  */
  let ArraySteps = [
    <LoaderComponent />,
    <AddressComponent formData={formData} setformData={setformData} setPage={setPage} value={formData?.shipping_address}
                      onChange={addressChange} page={page} plans={plans} />,
    <DoubleCheckAddress formData={formData} setPage={setPage} />,
    <InvalidForServiceAddress setPage={setPage} />,
    <InternetPlansComponent formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} />,
    <TvPlansComponent formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} />,
    <TvPlansOptional formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} />,
    <AddonPlansComponent formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} />,
    <ContactComponent formData={formData} setformData={setformData} setPage={setPage} plans={plans} addons={addons} page={page} value={formData?.mailing_address}
                      onChange={mailAddressChange} />,
    <CheckoutComponent formData={formData} setformData={setformData} setPage={setPage} plansData={plans} addonsData={addons} page={page} />,
    <PaymentSuccessComponent setPage={setPage} />
  ];
  return (ArraySteps[page]);
}

export default PickPlans